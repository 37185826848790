// index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { ThemeProvider } from './ThemeContext';
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga4';

const root = ReactDOM.createRoot(document.getElementById('root'));

// Initialize Google Analytics
ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);

root.render(
  <React.StrictMode>
    <ThemeProvider>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

// Register the service worker for PWA features
serviceWorkerRegistration.register();

// Pass the function to log results (e.g., reportWebVitals(console.log))
// or send to an analytics endpoint.
reportWebVitals();
