// App.js
import React, { useEffect, useState, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga4';
import './App.css';

// Lazy load components
const Header = React.lazy(() => import('./components/Header'));
const HeroSection = React.lazy(() => import('./components/HeroSection'));
const AboutMe = React.lazy(() => import('./components/AboutMe'));
const Experience = React.lazy(() => import('./components/Experience'));
const Education = React.lazy(() => import('./components/Education'));
const Projects = React.lazy(() => import('./components/Projects'));
const Skills = React.lazy(() => import('./components/Skills'));
const Contact = React.lazy(() => import('./components/Contact'));

function App() {
  const [scrollTop, setScrollTop] = useState(0);

  // Initialize AOS (Animate On Scroll) if still using it
  // If replaced with Framer Motion everywhere, you can remove this
  useEffect(() => {
    // AOS.init({ duration: 1000 });
  }, []);

  // Scroll progress indicator
  useEffect(() => {
    const handleScroll = () => {
      const winScroll =
        document.body.scrollTop || document.documentElement.scrollTop;
      const height =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      const scrolled = (winScroll / height) * 100;
      setScrollTop(scrolled);
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Initialize Google Analytics and track page views
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
  }, []);

  return (
    <>
      <Helmet>
        <title>Your Name | Agile Developer & AI Enthusiast</title>
        <meta
          name="description"
          content="Agile developer with a passion for AI and Machine Learning."
        />
        {/* Open Graph tags */}
        <meta property="og:title" content="Your Name" />
        <meta
          property="og:description"
          content="Agile developer with a passion for AI and Machine Learning."
        />
        <meta property="og:image" content="images/profile.webp" />
        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        {/* Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'Person',
            name: 'Your Name',
            jobTitle: 'Agile Developer & AI Enthusiast',
            url: 'https://yourwebsite.com',
            sameAs: [
              'https://www.linkedin.com/in/yourprofile',
              'https://github.com/yourusername',
            ],
          })}
        </script>
      </Helmet>
      {/* Scroll progress indicator */}
      <div
        className="fixed top-0 left-0 w-full h-1 bg-blue-600"
        style={{ width: `${scrollTop}%` }}
      ></div>

      {/* Wrap components with Suspense for lazy loading */}
      <Suspense fallback={<div>Loading...</div>}>
        <Header />
        <HeroSection />
        <AboutMe />
        <Experience />
        <Education />
        <Projects />
        <Skills />
        <Contact />
      </Suspense>
    </>
  );
}

export default App;
